import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { css, Global } from '@emotion/react';
import styled from 'styled-components';
import SEO from '../components/organisms/seo';
import Close from '../components/atoms/close';
import { media } from '../utils/media-queries';
import RichText from '../components/atoms/richtext';

const ImpressumPage = ({ data }) => {
  const { _rawContent } = data.impressum.nodes[0];

  return (
    <>
      <Global
        styles={css`
          .main-wrapper {
            background: var(--b);
            color: var(--w);
          }
        `}
      />
      <SEO siteTitle="Impressum" />
      <StyledProject>
        <h1 className="hl">Impressum</h1>
        <Close />
        <div className="text">
          <RichText blocks={_rawContent} />
        </div>
      </StyledProject>
    </>
  );
};

const StyledProject = styled.section`
  display: flex;
  flex-direction: column;

  h1 {
    padding-top: var(--sp-1-3);
    padding-left: var(--sp-1-3);
    padding-bottom: var(--sp-5);
    color: var(--w);
    display: block;
    z-index: 10;
  }

  .text {
    text-transform: none;
    padding-left: var(--sp-1-3);
    max-width: 90%;

    p {
      padding-bottom: var(--sp-1-3);
    }
  }

  @media ${media.M} {
    h1 {
      position: sticky;
      padding-bottom: var(--sp-8);
      top: 0;
    }

    .text {
      position: sticky;
      top: calc(var(--fs-hl) + var(--sp-8) + var(--sp-1-3));
      left: 0;
      align-self: start;
      max-width: 33%;
    }
  }
`;

export const query = graphql`
  query LegalNotice {
    impressum: allSanityImpressum {
      nodes {
        _rawContent
      }
    }
  }
`;

ImpressumPage.propTypes = {
  data: PropTypes.object,
};

export default ImpressumPage;
